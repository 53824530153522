import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "allCheckbox", "selectedCount", "selectAllLink"];
  static values = { total: String, allSelected: Boolean };

  connect() {
    this.allSelectedValue = false;
    this.update();
  }

  // select all, including outside the current page
  selectAll() {
    this.allSelectedValue = true;
    this.update();
  }

  // trigger on checkbox toggle
  update() {
    if (!this.hasSelectedCountTarget) return;
    if (!this.hasSelectAllLinkTarget) return;

    if (!this.allVisibleSelected) this.allSelectedValue = false;

    const total = this.totalValue;
    if (this.allSelectedValue) {
      this.selectedCountTarget.textContent = `${total}/${total}`;
    } else {
      this.selectedCountTarget.textContent = `${this.selectedCheckboxes}/${total}`;
    }

    this.setAllCheckboxValue();
    this.setLinkVisibility();
  }

  setAllCheckboxValue() {
    this.allCheckboxTarget.checked = this.allVisibleSelected;
  }

  setLinkVisibility() {
    var visibility = 'hidden';

    if (this.allVisibleSelected && !this.allSelectedValue) {
      visibility = 'visible';
    }

    if (this.totalValue === this.selectedCheckboxes) {
      visibility = 'hidden';
    }

    this.selectAllLinkTarget.style.visibility = visibility;
  }

  get allVisibleSelected() {
    return this.checkboxTargets.every(checkbox => checkbox.checked);
  }

  get selectedCheckboxes() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked).length.toString();
  }
}
