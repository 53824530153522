import { Controller } from '@hotwired/stimulus';
import { SegmentedMessage } from 'sms-segments-calculator';


export default class SmsLengthController extends Controller {
  static targets = ['message', 'submit'];

  connect() {
    this.update();
  }

  update() {
    const messageValue = this.messageTarget.value;
    const segments = new SegmentedMessage(messageValue);
    const { segmentsCount: messageSize } = segments;
    const maximumSize = 10;

    const isTooLong = messageSize > maximumSize;
    this.submitTarget.disabled = isTooLong;

    if (isTooLong) {
      this.showFeedback();
    } else {
      this.hideFeedback();
    }
  }

  showFeedback() {
    // If the feedback el isn't already inserted, create and insert it
    if (!this.feedbackElement) {
      this.feedbackElement = document.createElement('div');
      this.feedbackElement.className = 'invalid-feedback';
      this.feedbackElement.textContent = 'Your message is too long.';
      this.feedbackElement.style.display = 'inline-block';
      this.messageTarget.insertAdjacentElement('afterend', this.feedbackElement);
    }
  }

  hideFeedback() {
    if (this.feedbackElement) {
      this.feedbackElement.remove();
      this.feedbackElement = null;
    }
  }
}
